/* eslint-disable no-undef */
import React from 'react';
import { Link } from 'gatsby';
import appstore from '../images/appstore.svg';
import googleplay from '../images/googleplay.svg';
import instagram from '../images/instagram.svg';
import facebook from '../images/facebook.svg';
import twitter from '../images/twitter.svg';
import linkedin from '../images/linkedin.svg';
import youtube from '../images/youtube.svg';
import nka from '../images/nka.jpg';
const Footer = () => {
  return (
    <div className="footer relative w-screen h-auto bg-white">
      <div className="flex flex-col items-center overflow-x-hidden relative w-full">
        <p className="my-8 mt-16 font-display text-center text-2xl uppercase">
          Download <br className="sm:block md:hidden lg:hidden xl:hidden" />
          the app.
        </p>
        <div className="flex-row">
          <button
            type="button"
            onClick={() => {
              if (typeof window !== `undefined`) {
                window.open(
                  'https://apps.apple.com/app/id1518032115',
                  '_blank',
                );
              }
            }}
            className="mr-10"
          >
            <img className="h-12" alt="Midi converter for ios" src={appstore} />
          </button>
          <button
            type="button"
            onClick={() => {
              if (typeof window !== `undefined`) {
                window.open(
                  'https://play.google.com/store/apps/details?id=com.kodai.kodaiapp2',
                  '_blank',
                );
              }
            }}
          >
            <img
              className="h-12"
              alt="Midi converter for android"
              src={googleplay}
            />
          </button>
        </div>
        <div className="flex-row mt-8">
          <button
            className="mr-4 focus:outline-none"
            type="button"
            onClick={() => {
              if (typeof window !== `undefined`) {
                window.open('https://www.instagram.com/kodaiapp', '_blank');
              }
            }}
          >
            <img className="h-6" alt="KODAI instagram page" src={instagram} />
          </button>
          <button
            className="mr-4 focus:outline-none"
            type="button"
            onClick={() => {
              if (typeof window !== `undefined`) {
                window.open('https://www.facebook.com/kodaiapp', '_blank');
              }
            }}
          >
            <img className="h-6" alt="KODAI facebook page" src={facebook} />
          </button>
          <button
            className="mr-4 focus:outline-none"
            type="button"
            onClick={() => {
              if (typeof window !== `undefined`) {
                window.open('https://twitter.com/KodaiApp', '_blank');
              }
            }}
          >
            <img className="h-6" alt="KODAI twitter page" src={twitter} />
          </button>
          <button
            className="mr-4 focus:outline-none"
            type="button"
            onClick={() => {
              if (typeof window !== `undefined`) {
                window.open(
                  'https://www.linkedin.com/company/kodai-app',
                  '_blank',
                );
              }
            }}
          >
            <img className="h-6" alt="KODAI linkedin page" src={linkedin} />
          </button>
          <button
            className="focus:outline-none"
            type="button"
            onClick={() => {
              if (typeof window !== `undefined`) {
                window.open(
                  'https://www.youtube.com/channel/UC9YBomvIzjTdO9pM2Rq3rcA',
                  '_blank',
                );
              }
            }}
          >
            <img className="h-6" alt="KODAI youtube page" src={youtube} />
          </button>
        </div>
        <p className="font-alternate text-lg mt-4">info@kodai.app</p>
        <p className="font-alternate text-lg mt-10 mb-10">© Kodai, 2021</p>
        <Link to="/aszf.pdf" className="font-alternate text-lg">
          Terms of use
        </Link>
        <Link to="/privacy.pdf" className="font-alternate text-lg mb-4">
          Privacy Policy
        </Link>
        <img className="h-24  mb-16" alt="NKA" src={nka} />
      </div>
    </div>
  );
};

export default Footer;
