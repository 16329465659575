import React from 'react';
import SEO from '../components/seo';
import Menu from '../components/navbar/Menu';
import Footer from '../components/footer';
import Card from '../components/card';

// img
import szabi from '../images/szabi.png';
import vera from '../images/vera.png';
import patrik from '../images/patrik.png';

function AboutUs() {
  return (
    <div className="flex w-screen overflow-x-hidden">
      <SEO
        title="KODAI APP | About us"
        description="Transcribe your audio recordings into midi files and music sheets using state of the art AI technology. Focus on your creative song-writing flow by saving time on music notation."
        url="/about-us"
      />
      <div className="flex-col flex">
        <Menu blog={false} home={false} />
        <div className="flex flex-col w-screen bg-white pt-24 sm:px-8 md:px-8 lg:px-24 xl:px-24 items-start">
          <div className="font-display text-black text-4xl ml-4">ABOUT US</div>
          <div className="pt-12 font-alternate items-start text-black text-lg pl-4 text-left xl:text-left sm:w-full md:w-full lg:w-full xl:w-5/6 pb-12">
            We wanted to help musicians to make songwriting easier - transcribe
            audio to MIDI, without the worry of losing the creative
            spark. <br /> <br /> The idea came up in a phone conversation during the
            2020 spring quarantine, as a wild thought: &quot;…how cool it
            would be if you could just play something on the guitar and the midi
            with the music sheet would appear? This would make the whole process
            so much easier and faster.&quot; <br /> <br /> So the seed grew, and here
            it is: KODAI aims to help musicians to quickly get through the
            technical and time-consuming aspects of transcribing.
          </div>
        </div>
        <div className="flex flex-col bg-salmon items-center sm:px-10 md:px-10 lg:px-24 xl:px-24 py-12">
          <div className="flex sm:flex-col md:flex-col lg:flex-row xl:flex-row justify-between">
            <Card
              img={szabi}
              title="Szabolcs Marton"
              subtitle=" "//"FOUNDER, APP, FRONT, AI-BACK, MANAGEMENT"
              text="My love for music is so wide-ranging. I composed music for theater performances. I play guitar every day to relax my mind. And as a programmer, I still found my way to music in Kodai. This app is my passion and I'm developing new features. 🙃"
            />
            <Card
              img={vera}
              title="Veronika Kiss"
              subtitle=" "//"FOUNDER, BACK, FRONT, MARKETING"
              text="I am surrounded by musicians, live with one and of course music was always important for me and listened to a lot of genres. I am learning to play on an ukulele and sometimes messing around in Reaper. To not just talk about music - I am a proud dog owner."
            />
          </div>
          <div className="sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
            <Card
              img={patrik}
              title="Patrik Kiss"
              subtitle=" "//"DATA SCIENCE, AI, ANIMATION"
              text="Been around music from elementary school. Was always in love with computers so interest in the fusion of them came early. Learned the basics on the field of CG and DSP which then extended onto data science and machine learning."
            />
            {/* <Card
              img={sampleprofile}
              title="Zsolt Marton"
              subtitle="UI DESIGN"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vitae quam metus. Aenean posuere arcu nec tellus placerat, vel pretium neque sagittis. Pellentesque finibus, ligula ac tempor sagittis, turpis neque vehicula nisi, sit amet tempor felis ipsum in leo."
            /> */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
