import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ img, title, subtitle, text }) => {
  return (
    <div className="flex flex-col sm:items-center lg:items-start sm:mb-16 md:mb-16 lg:mb-2 xl:mb-2">
      <div className="flex flex-row py-12">
        <div className="flex sm:justify-center lg:justify-start sm:w-full lg:w-1/2 sm:pr-0 md:pr-0 lg:pr-6 xl:pr-6">
          <div className="relative w-64 h-64 z-0">
            <img
              src={img}
              alt="profile"
              className="absolute z-0 h-full w-full rounded shadow object-cover"
            />
          </div>
        </div>
        <div className="sm:hidden flex flex-col sm:w-full md:w-full lg:w-1/2 xl:w-1/2">
          <h2 className="sm:hidden font-display sm:text-lg md:text-lg lg:text-xl xl:text-2xl text-black uppercase pb-1">
            {title}
          </h2>
          <p className="sm:hidden font-display text-black uppercase pb-1">
            {subtitle}
          </p>
          <p className="sm:hidden md:hidden lg:hidden font-alternate text-black pr-4">
            {text}
          </p>
        </div>
      </div>
      <h2 className="sm:block md:hidden lg:hidden xl:hidden font-display sm:text-xl md:text-xl lg:text-2xl xl:text-4xl text-black uppercase py-4">
        {title}
      </h2>
      <p className="sm:block md:hidden lg:hidden xl:hidden font-display text-black uppercase pb-4">
        {subtitle}
      </p>
      <p className="sm:block md:block lg:block xl:hidden font-alternate text-black pr-4">
        {text}
      </p>
    </div>
  );
};

Card.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Card;
